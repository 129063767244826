.customList {
    flex-direction: column;
    gap: 12px;
    width: fit-content;
    line-height: 16px;
    color: #A4A8B1;
}

.customList__item {
    display: flex;
    width: fit-content;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.customList__item-input[type=radio] {
    accent-color: #5932EA;
    width: 16px;
    height: 16px;
    margin: 4px;
    cursor: pointer;
 }

.customList__item-input[type=checkbox] {
    display: none;
 }

.customChecklist-item {
    height: 13.5px;
    width: 13.5px;
    margin: 5px;
    margin-right: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border-radius: 4px;
    transition: border 0.3s ease-in-out;
}

.customChecklist-item:hover {
    border: 1px solid #5932EA;
}

.customList__item-input[type=checkbox]:checked + .customChecklist-item::before {
    width: 13.5px;
    height: 13.5px;
    content: '0';
    left: -1px;
    top: -0.8px;
    position: absolute;
    color: transparent;
    background-image: url(../../icons/svg/Subtract-colored.svg);    
    background-repeat: no-repeat;
    border-radius: 4px;
    animation: contextFade 0.3s both;
}


.customList__input-container__input {
    width: 25%;
    min-width: 36px;
    max-width: 104px;
    height: 32px;
    font-size: 12px;
    line-height: 16px;
    color: #A4A8B1;
    border: 1px solid #E1E4ED;
    border-radius: 6px;
    background-color: #F8FAFB;
    text-align: center;
    outline: none;
    transition: all ease-in-out 0.3s;
}

.customList__input-container__input:focus,
.customList__input-container__input:hover {
    border: 1px solid #5932EA;
    transition: all ease-in-out 0.3s;
}

.customList__input-container__input:focus::-webkit-input-placeholder {
    text-indent: 1000px;
}
