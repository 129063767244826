.fadeAdd-out {
    animation: fadeOutAdd 0.2s none;
}

.fadeAdd-in {
    animation: fadeInAdd 0.2s none;
}

@keyframes fadeInAdd {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }  100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeOutAdd {
    from {
        opacity: 1;
        transform: scale(1);
    } to {
        opacity: 0;
        transform: scale(0.9);
    }
}