.DatePicker {
    display: flex;
    flex-direction: row;
    width: 130px;
    height: 30px;
    background-color: #F9FAFB;
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    padding: 6px 7px;
    margin-right: 15px;
    transition: all ease-in-out 0.3s;
}

.DatePicker.active,
.DatePicker:focus,
.DatePicker:hover {
    color: #5932EA;
    border: 1px solid #5932EA;
    transition: all ease-in-out 0.3s;
}

.DatePicker:hover{
    cursor: pointer;
}

.DatePicker:hover .DatePicker-icon,
.DatePicker.active .DatePicker-icon,
.DatePicker:focus .DatePicker-icon {
    stroke: #5932EA;
    transition: all ease-in-out 0.3s;
}

.DatePicker-icon {
    stroke: #A4A8B1;
    transition: all ease-in-out 0.3s;
}

.DatePicker-container {
    flex: auto;
    margin-left: 4px;
}

.DatePicker-container .active {
    color: #232360;
    transition: all ease-in-out 0.3s;
}

.DatePicker-container__title {
    user-select: none;
    text-align: center;
    font-size: 10px;
}

.DatePicker-calendar {
    position: absolute;
}

.DatePicker-calendar__container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    opacity: 0.2;
    background-color: #000;
}

.DatePicker-calendar__Calendar {
    top: 50%;
    left: 50%; 
    position: absolute;
    background-color: #fff;
    border-radius: 12px;
    padding: 15px;
    box-shadow: 0px 0px 25px 0px rgba(35, 35, 96, 0.15);
    transform: translate(0%, -50%);
}

.DatePicker-calendar__Calendar-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
