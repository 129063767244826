.Calendar {
    display: flex;
    min-width: 290px;
    flex-direction: column;
    justify-content: center;
    max-width: 280px;
}

.Calendar-Navi__currentData-list__item span {
    font-size: 18px;
    line-height: 24px;
}

.Calendar-Navi {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Calendar-Navi__currentData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    gap: 14px;
    line-height: 24px;
}

.Calendar-Navi__currentData-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    gap: 14px;
}

.Calendar-Nave__nextMonth {
    margin-left: auto;
}

.Calendar-Navi__previousMonth {
    margin-right: auto;
}

.Calendar-Navi__previousMonth,
.Calendar-Nave__nextMonth {
    position: relative;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.Calendar-Navi__previousMonth.pastMonth {
    pointer-events: none;
}

.Calendar-Navi__previousMonth.pastMonth .Calendar-Navi__previousMonth-arrow {
    border-color: #A4A8B1;
    pointer-events: none;
}

.Calendar-Navi__previousMonth-arrow,
.Calendar-Navi__nextMonth-arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 3px solid #232360;
    border-left: 3px solid #232360;
}

.Calendar-Navi__previousMonth-arrow {
    top: 50%;
    left: 20%;
    transform:rotateZ(-45deg) translate(50%, 0%);
}

.Calendar-Navi__nextMonth-arrow {
    top: 10%;
    right: 20%;
    transform:rotateZ(135deg) translate(50%, 0%);
}

.Calendar-rangeDescription {
    display: flex;
    height: 24px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    color: #232360;
    font-weight: 500;
    opacity: 0;
    transition: all ease-in 0.3s;
}

.Calendar-rangeDescription.active {
    opacity: 1;
    transition: all ease-in 0.3s;
}   

.Calendar-table {
    overflow: hidden;
    animation: contextFade 0.3s both;
}

.Calendar-table__thead-tr-th {
    font-size: 18px;
    color: #232360;
    font-weight: 400;
    text-align: center;
    cursor: default;
    user-select: none;
}

.Calendar-table__tbody {
    animation: contextFade 0.3s both;
}

.Calendar-table__tbody-tr-td {
    position: relative;
    width: 40px;
    height: 40px;
    font-size: 18px;
    color: #232360;
    font-weight: 400;
    text-align: center;
    border: none;
    padding: 0;
    user-select: none;
    cursor: pointer;
}

.Calendar-table__tbody-tr-td p {
    position: relative
}


.Calendar-table__tbody-tr-td:nth-child(n+6):nth-child(-n+7).showWeekend,
.Calendar-table__thead-tr-th:nth-child(n+6):nth-child(-n+7).showWeekend {
    color: #F85640;
}

.Calendar-table__tbody-tr-td:hover p {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    color: #FFF;
    cursor: pointer;
    background-color: #5932EA;
}



.Calendar-table__tbody-tr-td p.currentDay {
    font-weight: 700;
}

.Calendar-table__tbody-tr-td p.selectedDay {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #5932EA;
    color: #FFF;
    border-radius: 50%;
}
 
.Calendar-table__tbody-tr-td p.ranged {
    position: relative;
    background-color: #F2EEFF;
}

.Calendar-table__tbody-tr-td:hover p.ranged {
    color: #FFF;
    background-color: #5932EA;
}

.Calendar-table__tbody-tr-td.Calendar-table__tbody-tr-td:nth-child(n) p.ranged::before {
    content: '';
    display: flex;
    align-items: center;
    width: 20px;
    height: 27px;
    position: absolute;
    left: -10px;
    z-index: -99;
    background-color: #F2EEFF;
}

.Calendar-table__tbody-tr-td.Calendar-table__tbody-tr-td:nth-last-child(n) p.ranged::after {
    content: '';
    width: 20px;
    height: 27px;
    position: absolute;
    z-index: -99;
    background-color: #F2EEFF;
}

.Calendar-table__tbody-tr-td:hover.Calendar-table__tbody-tr-td:nth-last-child(n) p.ranged::after {
    content: '';
    width: 20px;
    height: 27px;
    right: -10px;
    position: absolute;
    background-color: #F2EEFF;
}

.Calendar-table__tbody-tr-td.pastDay-showWeekend,
.Calendar-table__tbody-tr-td.pastDay {
    pointer-events: none;
    color: #C6C7F8;
}

.Calendar-table__tbody-tr-td:nth-child(n+6):nth-child(-n+7).pastDay-showWeekend {
    color: #f7705e;
}

.monthNamesPlates {
    display: flex;
    width: 280px;
    height: 229px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:space-between;
    align-items: center;
    animation: contextFade 0.3s both;
}

.monthNamesPlates div {
    width: 85px;
    text-align: center;  
}

.monthNamesPlates__span {
    display: block;
    width: 85px;
    padding: 4px 10px;
    border: 1px solid #E1E4ED;
    color: #232360;
    font-size: 14px;
    background-color: #FFF;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
}

.monthNamesPlates__span.currentSelect,
.monthNamesPlates__span:hover {
    cursor: pointer;
    color: #5932EA;
    border: 1px solid #5932EA;
    transition: all 0.2s ease-in-out;
}

.monthNamesPlates__span.current {
    font-weight: 600;
}

.monthNamesPlates__span.pastMonth {
    pointer-events: none;
    color: #C6C7F8;
}