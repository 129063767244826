.buttons-group {
    width: 258px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
}

.buttons-group__element {
    display: block;
    border-bottom: 2px solid transparent;
    padding-bottom: 3px;
    text-align: center;
    font-size: 16px;
    color: #232360;
    margin: 0;
}

.buttons-group__element:hover {
    cursor: pointer;
    color: #5932EA;
    border-bottom: 2px solid #5932EA;
}

.buttons-group__element.activePeriod {
    color: #5932EA;
    border-bottom: 2px solid #5932EA;
}


