.tableContainer {
    width: 280px;
    height: 200px;
    font-size: 18px;
    color: #232360;
    line-height: 24px;
    text-align: center;
}

.tableContainer__td {
    padding: 0;
    color: #232360;
    user-select: none;
    border-radius: 50%;
}

.tableContainer__td-button {
    padding: 0;
    user-select: none;
    /* width: 160px; */
    
}

.tableContainer__td-button div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* width: 160px; */
}

.tableContainer__td div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 34px;
    height: 34px;
}

.tableContainer__td.selected div {
    padding: 0;
    cursor: pointer;
    color: #FFF;
    background-color: #5932EA;
    border-radius: 50%;
}

.tableContainer__td:hover div {
    padding: 0;
    cursor: pointer;
    color: #FFF;
    border-radius: 50%;
    background-color: #5932EA;
}


