
.modalWindow {
    position: fixed; /* фиксированное положение */
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5); /* цвет фона */
    display: none;
    justify-content: center;
    z-index: 1055;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    scrollbar-width: none;
    padding: 20px 0px;
    /* opacity: 0; */
    /* visibility: hidden; */
    /* transition: all 0.3s ease */
}

.modalWindow.open {
    display: flex;
}



.modalWindow__container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    max-height: fit-content;
    padding: 24px 28px;
    top: -50px;
    background-color: #FFFFFF;
    border-radius: 10px;
    gap: 28px;
    opacity: 0;
    margin: auto;
    font-size: 12px;
    color: #232360;
    transition: all ease-in 0.3s;
}

.modalWindow.open .modalWindow__container{
    animation-name: ModalDefaultTransition;
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

.modalWindow__logo {
    position: relative;
    display: flex;
    align-items: center;
}

.modalWindow__close {
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
}

.modalWindow__close:hover .modalWindows_close-btn {
    fill: #5932EA;
}

.modalWindows_close-btn {
    fill: #AC98F6;
}

.modalWindow__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: fit-content;
}

.modalWindow__title {
    /* width: 444px; */
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: #5932EA;
    /* margin-bottom: 16px; */
}

.modalWindow__title.default {
    color: #232360;
}


.modalWindow__body {
    display: flex;
    align-items: center;
}

.modalWindow__header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.modalWindow__body-description,
.modalWindow__body-description__bolt  {
    font-size: 12px;
    line-height: 16px;
    color: #232360;
}

.modalWindow__body-description {
    font-weight: 400;
}

.modalWindow__body-description__bolt {
    font-weight: 600;
    margin-left: 3px;    
}

.modalWindow__btn-group {
    display: flex;
}

.modalWindow__btn-group__close,
.modalWindow__btn-group__save {
    line-height: 16px;
    font-size: 12px;
    padding: 7px;
    border: none;
    border-radius: 6px;
    background-color: transparent;
    outline: none;
}

.modalWindow__btn-group__close {
    width: 61px;
    border: 1px solid #E1E4ED;
    background-color: #FFFFFF;
    color: #232360;
    margin-right: 8px;
}

.modalWindow__btn-group__save {
    width: 82px; 
    background-color: #5932EA;
    color: #FFFFFF;
    font-weight: 600;
}

@keyframes ModalDefaultTransition {
    0% {
      opacity: 0;
      top: -50px;
    }
    100% {
        opacity: 1;
        top: 0px;
    }
  }

@media (max-width: 431px) {
    .modalWindow__title {
        font-size: 14px;
    }
}