
.repeatTemplate {
    margin-left: 21px;
}

.repeatTemplate__option-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.repeatTemplate__description {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: rgba(35, 35, 96, 1);
}

.repeatTemplate__description-logo {
    transition: fill ease-in-out 0.2s;
    fill: rgba(35, 35, 96, 1);
}

.repeatTemplate__btn-descr {
    display: none;
}

.repeatTemplate__btn__icon {
    width: 16px;
    height: 16px;
    transition: fill ease-in-out 0.2s;
    fill: #A4A8B1;
    cursor: pointer;
}

.active__btn {
    transition: fill ease-in-out 0.2s;
    fill: #5932EA;
}

.repeatTemplate__btn__icon:hover {
    fill: #5932EA;
}

.repeatTemplate__option-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 0;
}

.repeatTemplate__option-el {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
}

.repeatTemplate__option-el:hover {
    background-color: rgba(242, 238, 255, 1);
}

.repeatTemplate__option-el:hover::before {
    content: '';
    position: absolute;
    left: 0;
    display: block;
    width: 20px;
    height: 16px;
    background-color: rgba(242, 238, 255, 1);
}

.repeatTemplate__option-el:hover::after {
    content: '';
    position: absolute;
    right: 0;
    display: block;
    width: 20px;
    height: 16px;
    background-color: rgba(242, 238, 255, 1);
}

.alert_template .repeatTemplate__btn__icon {
    transition: fill ease-in-out 0.3s;
    fill: #F85640;
}

.picketOption {
    background-image: url(../../icons/svg/check-priority.svg);
    background-repeat: no-repeat;
    background-position: right center;
}

.detailConfig {
    display: flex; 
    max-width: 382px; 
    min-width: 357px; 
    width: auto; 
    height: auto; 
    flex-direction: column;
    padding: 16px 16px;
    z-index: 99;
    transition: all ease-in 0.3s;
}

.detailConfig__container {
    transition: all ease-in 0.3s;
}

.detailConfig__heading {
    display: flex;
    font-size: 14px;
    gap: 8px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.detailConfig__intervalCount {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.detailConfig__intervalCount p,
.detailConfig__intervalTime p {
    font-size: 14px;
    line-height: 19px;
    width: max-content;
}

.detailConfig__intervalCount__input input {
    width: 36px;
    height: 32px;
    border: 1px solid #E1E4ED;
    border-radius: 6px;
    background-color: #F8FAFB;
    text-align: center;
    outline: none;
    transition: all ease-in-out 0.3s;
}

.detailConfig__intervalCount__input input:hover,
.detailConfig__intervalCount__input input:focus,
.detailConfig__intervalCount__input:checked input:focus {
    border: 1px solid #5932EA;
    transition: all ease-in-out 0.3s;
}

.detailConfig__intervalTime {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
    gap: 12px;
}

.detailConfig__calendarDotted {
    display: none;
    align-items: center;
}

.detailConfig__calendarDotted.expanded {
    display: block;
}


.detailConfig_classicCalendar {
    display: none;
    height: 0px;
}

.detailConfig_classicCalendar.expanded {
    display: flex;
    justify-content: center;
    height: 100%;
}

.deatilConfig__otherParams {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 14px;
}

.detailConfig__otherParamsContainer {
    display: flex;
    width: fit-content;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    user-select: none;
}

.detailConfig__resultContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 10px;
    line-height: 14px;
    color: #A4A8B1;
}

@media (max-width: 576px)  {
    .repeatTemplate {
        margin-left: 12xp;
    }
    .repeatTemplate__btn {
        display: flex;
        flex-grow: 1;
        flex-wrap: nowrap;
        white-space: nowrap;
        align-items: center;
        border: 1px solid #E1E4ED;
        border-radius: 5.65px;
        padding: 6px 7px;
        height: 36px;
        gap: 6px;
    }
    .repeatTemplate__btn__icon {
        width: 20px;
        height: 20px;
        fill: #A4A8B1;
    }
    .repeatTemplate__btn-descr {
        display: block;
        font-size: 16px;
        line-height: 19px;
        color: #A4A8B1;
    }
    .active_template {
        border: 1px solid rgb(89, 50, 234);
        transition: border ease-in-out 0.3s;
    }
    .active_template .repeatTemplate__btn-descr{
        color: rgb(89, 50, 234);
        transition: color ease-in-out 0.3s;
    }
    .active_template .repeatTemplate__btn__icon {
        fill: rgb(89, 50, 234);
        transition: fill ease-in-out 0.3s;
    }
    .alert_template .repeatTemplate__btn__icon {
        fill: #F85640;
        transition: fill ease-in-out 0.3s;
    }
    .alert_template {
        border: 1px solid #F85640;
        transition: border ease-in-out 0.3s;
    }
    .alert_template .repeatTemplate__btn-descr {
        color: #F85640;
        transition: color ease-in-out 0.3s;
    }
}