.button {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    padding: 7.2px 15.2px;
    line-height: 16px;
    border-radius: 8px;
    box-sizing: border-box;
    outline: none;
    transition: all 0.2s ease-in-out;
    touch-action: manipulation;
}

.button.xs {
    font-size: 10px;
    line-height: 14px;
    padding: 4.2px 11.2px;
}

.button.xl {
    font-size: 15px;
    line-height: 20px;
    padding: 9.2px 15.2px;
}

.button.lg {
    font-size: 16px;
    line-height: 22px;
    padding: 11.2px 19.2px;
}

.button.adaptive {
    font-size: 15px;
    line-height: 20px;
    padding: 9.2px 15.2px;
}


.button.default {
    color: #232360;
    border: 1px solid #E1E4ED;
    background-color: #fff;
}

.button.filled {
    color: #FFF;
    border: 1px solid transparent;
    background-color: #5932EA;
}

button.hidden {
    display: none;
}

.button.filled:hover .loaderBtn {
    background: #5932EA;
}

.button.danger {
    color: #232360;
    border: 1px solid #E1E4ED;
    background-color: #fff;
}

.button.picked {
    color: #5932EA;
    border: 1px solid #5932EA;
    background-color: #fff;
}


.button.filled:hover,
.button.default:hover,
.button.default:active,
.button.filled:active {
    color: #5932EA;
    border: 1px solid #5932EA;
    background-color: #fff;
}


.button.danger:hover {
    color: #F85640;
    border: 1px solid #F85640;
    background-color: #fff;
}

.button.danger:hover .loaderBtn {
    background: #F85640;
}

.button:disabled {
    pointer-events: none;
    background-color: #AC98F6;
    border: 1px solid #AC98F6;
    transition: all 0.2s ease-in-out;
}

.button:disabled .loaderBtn {
    background: #fff;
}

.button.default:disabled {
    color: #fff;
}


.button__container{

}

.button__logo{

}

.button__title{

}

.loaderBtn {
    padding: 4px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #5932EA;
    margin-left: auto;
    margin-right: auto;
    --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}

.button.base .loaderBtn,
.button.xs .loaderBtn {
    width: 14px;
    padding: 3px;
}

.button.xl .loaderBtn {
    width: 20px;
}

.button.lg .loaderBtn {
    width: 22px;
}

@keyframes l3 {to{transform: rotate(1turn)}}

@media (max-width: 576px) {
    .button {
        font-size: 12px;
    }
    .button.adaptive {
        font-size: 10px;
        line-height: 14px;
        padding: 4.2px 11.2px;
    }
}


