.weekDots__list {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    margin: 0;
}

.weekDots__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background-color: #AC98F6;
    border-radius: 50%;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    transition: background-color ease 0.1s;
}

.weekDots__item:hover {
    background-color: #5932EA;
}

.weekDots__item.selected {
    background-color: #5932EA;
    transition: background-color ease 0.1s;
}

.weekDots__item:hover {
    user-select: none;
    cursor: pointer;
    transform: scale(1.1);
}